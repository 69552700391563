import React from 'react';

import Card from '@bit/semantic-org.semantic-ui-react.card'
import Button from '@bit/semantic-org.semantic-ui-react.button'
import Image from '@bit/semantic-org.semantic-ui-react.image'
import Icon from '@bit/semantic-org.semantic-ui-react.icon'
import Statistic from '@bit/semantic-org.semantic-ui-react.statistic'
import Popup from '@bit/semantic-org.semantic-ui-react.popup'
import Label from '@bit/semantic-org.semantic-ui-react.label'
import Grid from '@bit/semantic-org.semantic-ui-react.grid'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Responsive from '@bit/semantic-org.semantic-ui-react.responsive'

import {graphql, useStaticQuery} from "gatsby";

const users = [
    {
        name: 'Elliot Fu',
        bio: 'Elliot has been a member since July 2019',
        avatar: '/avatars/elliot.jpg',
    },
    {
        name: 'Stevie Feliciano',
        bio: 'Stevie has been a member since August 2019',
        avatar: '/avatars/stevie.jpg',
    },
    {
        name: 'Matt',
        bio: 'Matt has been a member since July 2019',
        avatar: '/avatars/matt.jpg',
    },
]

function shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

const siteQuery = graphql`
    {
        allRequestedApisJson(sort: {fields: followers, order: DESC}, limit: 200) {
            edges {
                node {
                    name
                    description
                    followers
                    id
                    image
                    providerAddress
                    payment
                    score
                    category
                }
            }
        }
    }
`;

const PopupExampleTitled = () => (
    <div>
        {users.map(user => (
            <Popup
                key={user.name}
                trigger={<Image src={user.avatar} alt={user.name} avatar/>}
                header={user.name}
                content={user.bio}
            />
        ))}
    </div>
)

const CardsList = () => {
    const results = useStaticQuery(siteQuery);
    const allApiListJson = results.allRequestedApisJson.edges;
    // console.log(JSON.stringify(allApiListJson, null, 2))

    return (
        <>
            { allApiListJson.map(api => {
                const path = require('../../../content/apis/requested/logos/' + api.node.image);
                let requests = (1 + Math.floor(Math.random() * 10)) * api.node.priority;
                return (
                <>
                    <Card color='yellow' key={api.node.id}>
                        <Card.Content>
                            <a href={api.node.providerAddress} target="new">
                                <Image floated='right' size='tiny' alt={api.node.name} src={path}/>
                            </a>
                            <Card.Header><a href={api.node.providerAddress} target="new">{api.node.name}</a></Card.Header>
                            <Card.Meta><b>{shorten(api.node.description,300, " ")}</b></Card.Meta>
                            <Card.Description>

                                <Statistic.Group widths='two' size='tiny'>
                                    <Statistic>
                                        <Statistic.Value >{api.node.score}</Statistic.Value>
                                        <Statistic.Label>Priority</Statistic.Label>
                                    </Statistic>

                                    <Statistic>
                                        <Statistic.Value>
                                            <Icon name='user'/>
                                            {api.node.followers}
                                        </Statistic.Value>
                                        <Statistic.Label>Requests</Statistic.Label>
                                    </Statistic>
                                </Statistic.Group>
                            </Card.Description>
                        </Card.Content>

                        <Card.Content extra>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column >
                                        {api.node.category}
                                    </Grid.Column>
                                    <Grid.Column only='computer mobile'>
                                        <Button as='div' labelPosition='right'>
                                            <Button color='red' onClick={ e => {
                                                // To stop the page reloading
                                                e.preventDefault();
                                                // Lets track that custom click
                                                trackCustomEvent({
                                                    // string - required - The object that was interacted with (e.g.video)
                                                    category: "Vote Button",
                                                    // string - required - Type of interaction (e.g. 'play')
                                                    action: "Click",
                                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                                    label: "Voting Campaign",
                                                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                                                    value: "1"
                                                });
                                                requests = requests + 1;
                                            }}>
                                                <Icon name='add' />
                                                Vote
                                            </Button>
                                            <Label as='a' basic color='red' pointing='left'>
                                                {api.node.followers}
                                            </Label>
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                </>

            )})}
        </>
    )
}


const RequestedApis: React.FC = () => {
    return (
        <div>
            <h1>Requested GraphQL APIs - please vote</h1>
            <br/>

            <Card.Group stackable itemsPerRow={4}>
                <CardsList/>
            </Card.Group>
            <br/>

            <Button content='Request a new API' primary/>
        </div>
    )
};


export default RequestedApis;
