import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import RequestedApis from "../components/Apis/RequestedApis";

function RequestedGraphQLPage() {

  let description = "GraphQL requested APIs - please vote to accelerate the implementation";

  return (
    <Layout>
      <SEO description={description} title={description}>
      </SEO>
      <Section>
          <div style={{ marginTop: "50px" }}>
              <Headings.h1>{description}</Headings.h1>
              <br/>
              <RequestedApis/>
          </div>
      </Section>
    </Layout>
  );
}

export default RequestedGraphQLPage;
